<footer
  class="relative w-full py-10 px-4 flex justify-center bg-gray-100 dark:bg-gray-900"
>
  <div class="w-full xl:max-w-screen-xl text-center flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <p>
        Created with <span class="text-pink-600">&#9829;</span> by Frankk
      </p>
      <p>
        <a
          href="https://sims4toolkit.com"
          target="_blank"
          rel="noreferrer"
          class="text-brand-s4tk-light dark:text-brand-s4tk-dark"
          >Sims 4 Toolkit</a
        >
        |
        <a
          href="https://twitter.com/frankkulakmods"
          target="_blank"
          rel="noreferrer"
          class="text-brand-twitter">Twitter</a
        >
        |
        <a
          href="https://patreon.frankkmods.com"
          target="_blank"
          rel="noreferrer"
          class="text-brand-patreon">Patreon</a
        >
        |
        <a
          href="https://ko-fi.com/frankkulak"
          target="_blank"
          rel="noreferrer"
          class="text-brand-kofi">Ko-fi</a
        >
        |
        <a
          href="https://discord.gg/qNhD3Jh"
          target="_blank"
          rel="noreferrer"
          class="text-brand-discord">Discord</a
        >
      </p>
    </div>
    <div class="text-xs flex flex-col gap-2">
      <p class="text-subtle">
        The Sims™ is a registered trademark of Electronic Arts, Inc. (EA).
        Neither me nor my creations are affiliated with or endorsed by EA.
      </p>
      <p class="text-subtle">
        All icons on this website are supplied by
        <a
          href="https://ionic.io/ionicons"
          target="_blank"
          rel="noreferrer"
          class="text-secondary">Ionicons</a
        >
        and
        <a
          href="https://primer.github.io/octicons/"
          target="_blank"
          rel="noreferrer"
          class="text-secondary">GitHub Octicons</a
        >.
      </p>
      <p class="text-subtle">2024 © Frankk</p>
    </div>
  </div>
</footer>
